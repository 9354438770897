/* global Modernizr */
/* global Pikaday */

window.onload = function() {
  setRequiredDates();
  bindCustomDateFields();
};

// Make dates required if necessary
//  For touch-devices, use the <input type=date>-field
//  For non-touch-devices, use three input-fields to input the date
function setRequiredDates() {
  var datesList = document.querySelectorAll('.form__item--date--required');
  for (var i = 0; i < datesList.length; ++i) {
    var dates = datesList[i];
    var name = dates.getAttribute('name');
    if (Modernizr && Modernizr.touchevents) {
      var completeDate = document.querySelector('#' + name);
      completeDate.setAttribute('required', 'required');
    } else {
      document.querySelector('#' + name + '_1').setAttribute('required', 'required');
      document.querySelector('#' + name + '_2').setAttribute('required', 'required');
      document.querySelector('#' + name + '_3').setAttribute('required', 'required');
    }
  }
}

// For non-touch-devices, use a custom date entry
//  It uses three input-fields to fill the <input type=date>-field
function bindCustomDateFields() {
  var datesList = document.querySelectorAll('.form__item--date');
  for (var i = 0; i < datesList.length; ++i) {
    var dates = datesList[i];
    var dateHelpers = dates.querySelectorAll('.form__control--date-helper');
    var name = dates.getAttribute('name');
    var completeDate = document.querySelector('#' + name);

    for (var j = 0; j < dateHelpers.length; ++j) {
      var dateHelper = dateHelpers[j];
      dateHelper.oninput = function() {
        var day = document.querySelector('#' + name + '_1');
        var month = document.querySelector('#' + name + '_2');
        var year = document.querySelector('#' + name + '_3');

        completeDate.value = year.value + '-' + month.value + '-' + day.value;
      };
    }
  }
}


// Menu functions
$(function() {
  $('.menu-trigger').on('click', function(e) {
    $('body').toggleClass('show-menu');
    e.preventDefault();
  });
});


// Employees slider
$(function() {
  // Header
  $('.employees').each(function () {
    var slidesCount = $(this).find('.employee').length;

    if (slidesCount > 1) {
      $(this).owlCarousel({
        itemElement: 'div',
        loop: false,
        items: 1,
        margin: 0,
        nav: false,
        dots: true,
        navText: ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],
        responsive: {
          600: {
            items: 2
          },
          1024: {
            items: 3
          }
        }
      });
    }
  });
});

// Bind datepicker
$(function() {
  var field = document.getElementById('datepicker');
  if (field) {
    var picker = new Pikaday({
      disableWeekends: true,
      field: field,
      firstDay: 1,
      i18n: i18n,
      minDate: new Date(),
      onSelect: function() {
        var selectedDate = new Date(picker.toString());
        field.value = selectedDate.getDate() + ' ' + i18n.months[selectedDate.getMonth()] + ' ' + selectedDate.getUTCFullYear();
      }
    });
  }
});

// Make repair progress sticky
$(function() {
  $('.progress').stick_in_parent({
    offset_top: 20
  });
});

// 💩 Create repair prototype functions
// $(function() {
//   // Check postal code field
//   $('[name="address--postal-code"]').on('change keyup', function() {
//     var $statusObject = $(this).closest('.repairs-form__value');
//     removeStatusClasses($statusObject);
//     if (isValidPostalCode($(this).val())) {
//       $statusObject.addClass('status--ok');
//     } else {
//       $statusObject.addClass('status--unknown');
//     }
//   }).on('blur', function() {
//     var $statusObject = $(this).closest('.repairs-form__value');
//     removeStatusClasses($statusObject);
//     if (isValidPostalCode($(this).val())) {
//       $statusObject.addClass('status--ok');
//     } else {
//       $statusObject.addClass('status--error');
//     }
//   });

//   // Check address number field
//   $('[name="address--number"]').on('change keyup', function() {
//     var $statusObject = $(this).closest('.repairs-form__value');
//     removeStatusClasses($statusObject);
//     if (isNumeric($(this).val())) {
//       $statusObject.addClass('status--ok');
//     } else {
//       $statusObject.addClass('status--unknown');
//     }
//   }).on('blur', function() {
//     var $statusObject = $(this).closest('.repairs-form__value');
//     removeStatusClasses($statusObject);
//     if (isNumeric($(this).val())) {
//       $statusObject.addClass('status--ok');
//     } else {
//       $statusObject.addClass('status--error');
//     }
//   });

//   // Check address addition field
//   $('[name="address--addition"]').on('change keyup', function() {
//     var $statusObject = $(this).closest('.repairs-form__value');
//     removeStatusClasses($statusObject);
//     $statusObject.addClass('status--ok');
//   });

//   // Check appointment fields
//   $('.appointment-form input[type="text"][required]').on('change keyup', function() {
//     var $statusObject = $(this).closest('.appointment-form__value');
//     removeStatusClasses($statusObject);
//     if ($(this).val() !== '') {
//       $statusObject.addClass('status--ok');
//     } else {
//       $statusObject.addClass('status--unknown');
//     }
//   }).on('blur', function() {
//     var $statusObject = $(this).closest('.appointment-form__value');
//     removeStatusClasses($statusObject);
//     if ($(this).val() !== '') {
//       $statusObject.addClass('status--ok');
//     } else {
//       $statusObject.addClass('status--error');
//     }
//   });

//   $('.appointment-form input[type="email"]').on('change keyup', function() {
//     var $statusObject = $(this).closest('.appointment-form__value');
//     removeStatusClasses($statusObject);
//     if (isValidEmailaddress($(this).val())) {
//       $statusObject.addClass('status--ok');
//     } else {
//       $statusObject.addClass('status--unknown');
//     }
//   }).on('blur', function() {
//     var $statusObject = $(this).closest('.appointment-form__value');
//     removeStatusClasses($statusObject);
//     if (isValidEmailaddress($(this).val())) {
//       $statusObject.addClass('status--ok');
//     } else {
//       $statusObject.addClass('status--error');
//     }
//   });

//   $('.appointment-form input[type="tel"]').on('change keyup', function() {
//     var $statusObject = $(this).closest('.appointment-form__value');
//     removeStatusClasses($statusObject);
//     if (isValidPhoneNumber($(this).val())) {
//       $statusObject.addClass('status--ok');
//     } else {
//       $statusObject.addClass('status--unknown');
//     }
//   }).on('blur', function() {
//     var $statusObject = $(this).closest('.appointment-form__value');
//     removeStatusClasses($statusObject);
//     if (isValidPhoneNumber($(this).val())) {
//       $statusObject.addClass('status--ok');
//     } else {
//       $statusObject.addClass('status--error');
//     }
//   });


//   // Show next question (prototype only)
//   $('.question--1 .button').on('click', function(e) {
//     e.preventDefault();
//     $('[name="address--postal-code"]').trigger('blur');
//     $('[name="address--number"]').trigger('blur');
//     var validNumber = isNumeric($('[name="address--number"]').val());
//     var validPostalCode = isValidPostalCode($('[name="address--postal-code"]').val());

//     var $errorMessage = $(this).closest('.question').find('.status--message');

//     if (validNumber && validPostalCode) {
//       nextQuestion();
//       $errorMessage.html('&nbsp;');
//     } else {
//       if (!validNumber && !validPostalCode) {
//         $errorMessage.html('Vul alsjeblieft je postcode en huisnummer in');
//       } else if (!validPostalCode) {
//         $errorMessage.html('Vul alsjeblieft je postcode in');
//       } else if (!validNumber) {
//         $errorMessage.html('Vul alsjeblieft je huisnummer in');
//       }
//     }
//   });

//   $('.question--icons input').on('change', function(e) {
//     e.preventDefault();
//     nextQuestion();
//   });

//   $('.question--dropdown select').on('change', function(e) {
//     e.preventDefault();
//     if ($(this).val() !== '') {
//       nextQuestion();
//     }
//   });
// });

// $(function() {
//   $('.progress__step').on('click', function() {
//     $('.progress__step').removeClass('progress__step--active');
//     $('.question--active').removeClass('question--active');

//     $(this).addClass('progress__step--active');
//     $('.repairs__flow .repairs__question:nth-child(' + ($(this).index() + 1) + ')').addClass('question--active');

//     scrollToActiveQuestion();
//   });
// });

// function nextQuestion() {
//   $('.repairs').find('.question--active').removeClass('question--active').next().addClass('question--active');
//   nextProgressQuestion();
//   scrollToActiveQuestion();
// }

// function nextProgressQuestion() {
//   $('.progress').find('.progress__step--active').removeClass('progress__step--active').next().addClass('progress__step--active');
// }

// function scrollToActiveQuestion() {
//   $('html, body').animate({
//     scrollTop: $('.question--active').offset().top - 20
//   }, 'slow');
// }

// Validation functions

// function removeStatusClasses(obj) {
//   $(obj).removeClass('status--ok status--unknown status--error');
// }

// function isValidPostalCode(input) {
//   var re = new RegExp(/^(\d{4})\s*([A-Za-z]{2})$/);
//   return re.test(input);
// }

// function isNumeric(input) {
//   var re = new RegExp(/^(0|[1-9][0-9]*)$/);
//   return re.test(input);
// }

// function isValidEmailaddress(input) {
//   var re = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
//   return re.test(input);
// }

// function isValidPhoneNumber(input) {
//   input = input.replace(/ /g, '');
//   var re = new RegExp(/(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/);
//   return re.test(input);
// }


// i18n of dates
var i18n = {
  previousMonth : 'vorige maand',
  nextMonth     : 'volgende maand',
  months        : ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
  weekdays      : ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
  weekdaysShort : ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za']
};


// FAQ
$(function() {
  $('.faq__question').on('click', function() {
    $(this).closest('.faq__item').toggleClass('faq__item--active');
  });
});

// Popup banner
$(function() {
	if ($('.popupbanner').length > 0) {
		var bannerID = $('.popupbanner').attr('id');

		$('.popupbanner__close, .popupbanner--bg').click(function(e) {
			$('body').toggleClass('showbanner');

			var expiryDate = new Date();
			expiryDate.setTime(expiryDate.getTime() + ((60 * 60 * 1000) * 24 * 365));
			$.cookie(bannerID, 'true', {
				expires: expiryDate,
				path: '/'
			});
			e.preventDefault();
		});

		$('.popupbanner__content .link').click(function() {
			$('body').toggleClass('showbanner');

			var expiryDate = new Date();
			expiryDate.setTime(expiryDate.getTime() + ((60 * 60 * 1000) * 24 * 365));
			$.cookie(bannerID, 'true', {
				expires: expiryDate,
				path: '/'
			});
		});

		if($.cookie(bannerID) === undefined) {
			setTimeout(function(){
				$('body').addClass('showbanner');
			}, 1000);
		}
	}
});
